<template>
    <div class="up_content_inner up_manage_site">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4 p-d-flex p-jc-between">
                <h2 class="up_page_title">Accessibility Scanning</h2>
                <div class="p-d-flex">
                    <Button type="button" icon="pi pi-plus" label="New Scan" class="p-button-primary" @click="scanNewWebsite = true; fetchRegWebsite()" />
                </div>
            </div>
            <div class="up_inner_container">
                <!--- Show Scaned Table Start --->
                <DataTable v-if="allScanedData.data" class="p-datatable p-mb-2" stripedRows :value="allScanedData.data.filter(e=> e.deletedAt === null)" :rowHover="true" :loading="allScanedData.loading" responsiveLayout="scroll" removableSort>
                    <template #loading>
                        Loading website data. Please wait.
                    </template>
                    <template #header>
                        <div class="p-d-flex p-jc-end">
                            <div class="p-d-flex p-align-center">
                                <span class="p-input-icon-left sup_search_box">
                                    <i class="pi pi-search" />
                                    <InputText v-model="allScanedData.search" placeholder="Search Website" @keyup.enter="fetchScanedWebsites('search')"/>
                                </span>
                                <Button v-if="allScanedData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchScanedWebsites('resetFilters')"/>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No data available in table.
                    </template>
                    <Column field="siteName" header="Site Name" style="text-align: center" :sortable="true">
                        <template #body="slotProps">
                            
                            <span class="up_scaned_details p-text-center">
                                <span class="up_scaned_site p-d-block">
                                    <strong>{{slotProps.data.websiteUrl}}</strong>
                                </span>
                                <span>1 Page, {{slotProps.data.totalViolationCount}} violations</span>
                            </span>
                        </template>
                    </Column>
                    <Column field="lastScanDate" header="Last Scaned" style="text-align: center" :sortable="true">
                        <template #body="slotProps">
                            <p>{{ dateDiff(new Date(slotProps.data.lastScanDate).getTime()) }} </p>
                        </template>
                    </Column>
                    <Column field="scanAllowed" header="Scan Allowed" style="text-align: center" :sortable="false">
                        <template #body="slotProps">
                            <label class="switch">
                                <input :data-value="slotProps.data.scanEnabledByAdmin" type="checkbox" role="switch" :id="slotProps.data.id" :checked="slotProps.data.scanEnabledByAdmin" disabled=true>
                                <span class="slider round"></span>
                            </label>
                        </template>
                    </Column>
                    <Column field="action" header="Action" style="text-align: center" :sortable="true">
                        <template #body="slotProps">
                            <Button type="button" icon="pi pi-eye" class="p-button-sm p-button-rounded p-button-text p-button-info" v-tooltip="'View Scan Report'" @click="$router.push({
                                name: 'CustomerScaningReport',
                                params: {
                                    uuid: slotProps.data.uuid
                                },
                            })" />
                            <!-- <Button v-if="slotProps.data.isScanEnabled" type="button" icon="pi pi-refresh" class="p-button-sm p-button-rounded p-button-text p-button-info" v-tooltip="'Re-scan'" @click="reScan(slotProps.data)" /> -->
                            <Button v-if="slotProps.data.weeklyScanCountRemaining > 0" type="button" icon="pi pi-refresh" class="p-button-sm p-button-rounded p-button-text p-button-info" v-tooltip="'Re-scan'" @click="confimationModal(slotProps.data)" />
                            <Button type="button" icon="pi pi-trash" class="p-button-sm p-button-rounded p-button-text p-button-danger" v-tooltip="'Delete Report'" @click="deleteReport(slotProps.data)" />
                        </template>
                    </Column>                    
                </DataTable>
                <!--- Show Scaned Table Start --->
            </div>
        </div>
        <Dialog header="Accessability Re Scan-" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="Yes" icon="pi pi-check" @click="reScan(confirmationModal.modaldata.data), confirmationModal.modalState = false" class="p-button-primary" />
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
            </template>
        </Dialog>
        <!--- Start New scan Modal --->
        <Dialog header="New Scan" v-model:visible="scanNewWebsite" :style="{width: '500px'}" :modal="true">
            <div class="up_new_scan">
                <form @submit.prevent="startScan()">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
                            <label for="scanSite">Which site would you like to scan?<span class="text-danger">*</span></label>
                            <Dropdown v-model="newScan.selectedWebsite" :options="newScan.websites" optionLabel="siteUrl" placeholder="Select Website" :disabled="newScan.scanProgress" />
                            <small class="p-invalid text-danger" v-if="newScan.websiteError">Website Name is required.</small>
                        </div>
                        <div class="p-field p-col-12">
                            <Button type="submit" label="Start Scan" class="p-button-primary up_btn_wrap" :disabled="newScan.scanProgress" />
                        </div>
                    </div>
                    <small v-if="newScan.scanProgress">Please wait, we are working on it...</small>
                </form>
            </div>
        </Dialog>
        <!--- Start New scan Modal --->
        <Toast position="bottom-left"/>
    </div>
</template>

<script>
import { userPortalWebsiteScan, userPortalWebsite, userPortalWebsiteRescan } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import { formatDate } from "../../../../helper/utility/utility"
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount, ref } from 'vue';
import { useToast } from "primevue/usetoast";

export default {
    name: 'AccessibilityScan',
    setup(){
        //Application store call
        const store = useStore();

        //Toast Message
        const toast = useToast();

        const scanNewWebsite = ref(false);

        //Rescan corfirmation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        });

        //Invoice initial data storing state
        const allScanedData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            search: null
        });

        const newScan = reactive({
            websites: null,
            selectedWebsite: '',
            websiteError: false,
            scanProgress: false
        })

        onBeforeMount(async() => {
            fetchScanedWebsites();
        });

        const fetchScanedWebsites = async() =>{
            try{
                allScanedData.loading = !allScanedData.loading;
                const response = await axios.get( userPortalWebsiteScan, {
                    params:{
                        status: 'all'
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allScanedData.data = response.data.data.websitescan;
                allScanedData.total = response.data.data.total;
                allScanedData.loading = !allScanedData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const fetchRegWebsite = async() => {
            try{
                const response = await axios.get( userPortalWebsite, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                newScan.websites = response.data.data.website;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const reScan = async(slotData) => {
            try{
                allScanedData.loading = !allScanedData.loading;
                let option = {
                        method: 'put',
                        params:{
                            uuid: slotData.uuid
                        },
                        url: userPortalWebsiteRescan,
                        headers: {'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                const response = await axios(option);
                if (response.data.status === 200) {
                    allScanedData.loading = !allScanedData.loading;
                    fetchScanedWebsites();
                }
            }catch(err){
                console.error(err);
            }
        }

        const startScan = async() => {
            if (newScan.selectedWebsite === '') {
                newScan.websiteError = true;
            } else {
                newScan.websiteError = false;
                try{
                    newScan.scanProgress = true;
                    const response = await axios.post( userPortalWebsiteScan, {
                        websiteUrl: newScan.selectedWebsite.siteUrl
                    }, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if (response.data.status === 200) {
                        newScan.scanProgress = false;
                        scanNewWebsite.value = false;
                        fetchScanedWebsites();
                        toast.add({severity:'success', summary:'Success', detail:response.data.data, life: 3000});
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }

        const deleteReport = async(slotData) => {
            try{
                allScanedData.loading = !allScanedData.loading;
                let option = {
                        method: 'delete',
                        params:{
                            uuid: slotData.uuid
                        },
                        url: userPortalWebsiteScan,
                        headers: {'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                const response = await axios(option);
                if (response.data.status === 200) {
                    allScanedData.loading = !allScanedData.loading;
                    fetchScanedWebsites();
                }
            }catch(err){
                console.error(err);
            }
        }

        /**
         * Confirmation on delete/restore
         */
        const confimationModal = (sloteData) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData
            }
        }

        function dateDiff(date) {

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        }
        

        return{
            scanNewWebsite,
            newScan,
            allScanedData,
            fetchScanedWebsites,
            formatDate,
            reScan,
            fetchRegWebsite,
            startScan,
            deleteReport,
            confirmationModal,
            confimationModal,
            dateDiff
        }
    }
}
</script>

<style>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>